// Import our custom CSS
import '../sass/main.scss';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// import { franchisematch } from './franchisematch/franchisematch.js'


document.querySelector("html").classList.remove("no-js");

// Detect all clicks on the document
document.addEventListener('click', (e) => {
	if (e.target.closest('.navbar-toggler')) {
    document.querySelector('.navbar-toggler').classList.toggle('nav-open');
    document.body.classList.toggle('nav-open');
  }
});


document.addEventListener("DOMContentLoaded", () => {
  // franchisematch()

  // ————————————————————————————————————————————————————————————
  // LOAD MODULES
  // ————————————————————————————————————————————————————————————
  if (document.querySelector('.fm-video-player')) {
    import('./components/video-player.js').then(module => {
      module.videoPlayer();
    });
  }
  
  if (document.querySelector('.splide')) {
    import('./components/slider.js').then(module => {
      module.slider();
    });
  }

});
